import React from "react";
const OutletOwnerTerms = () => {
  return (
    <div className="flex flex-col self-center mx-auto justify-center mt-12">
      <div className="self-center 3xl:w-1/2 mx-auto justify-center md:w-9/12">
        <h1 className="text-primary text-2xl font-bold 3xl:pb-24 pt-24 md:pb-12 text-center">
          Outlet Owner Terms and Conditions
        </h1>
        <ol className="custom-ol-style">
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              INTRODUCTION
            </span>
            <ol className="custom-ol-style text-base pb-4">
              <li className="my-1 pt-1 pb-4 pt-1 ">
                The Parties to these Terms & Conditions are Touchsides and the
                Vendor (called outlet owners in Touchsides onboarding form).
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides hereby offers the Vendor the opportunity to use the
                Touchsides Platform, subject to the Terms & Conditions set out
                herein.
              </li>
              <li className="my-1 pt-1 pb-4">
                These are the terms and conditions that govern your relationship
                with Touchsides and its Affiliates and that apply to the
                Touchsides Platform, and to all users of the Touchsides Platform
                (“Terms & Conditions”). These Terms & Conditions do not apply in
                isolation, but are to be read together with all other
                agreements, terms and conditions relating (directly or
                indirectly) to the applicable Touchsides Platform and such other
                incidental agreements entered into between you and Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                By accepting and continuing to use the Touchsides Platform, or
                any one of the functionalities within the Touchsides Platform,
                you confirm and agree to accept and be bound by these Terms &
                Conditions, which form a binding contract between you and us.
              </li>
              <li className="my-1 pt-1 pb-4">
                These Terms & Conditions shall commence from date of first use
                of the Touchsides Platform and shall continue indefinitely,
                subject to the appropriate written agreement relating to the
                applicable Touchsides Platform, entered into between the Vendor
                and Touchsides. These Terms & Conditions may be terminated at
                any time by Touchsides providing the Vendor with reasonable
                notice.
              </li>
              <li className="my-1 pt-1 pb-4">
                These Terms & Conditions may be amended by Touchsides acting in
                its sole discretion. Any amendments hereto will apply with
                effect from the date recorded therein, and will be posted on
                Touchsides’s website or notified to the Vendor through the
                Touchsides Platform and will supersede and replace all previous
                versions of these Terms & Conditions. Touchsides has no
                obligation to inform the Vendor of such change in advance and
                the Vendor is responsible for keeping up to date with amendments
                itself.
              </li>
              <li className="my-1 pt-1 pb-4">
                You can contact Touchsides on 010 312 5105 or{" "}
                <a href="mailto:info@holaclub.co.za">
                  <font color="blue">info@holaclub.co.za</font>
                </a>
                . We will communicate with you in the English language.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Definitions
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                “Affiliate” means (a) any subsidiary or a holding company of
                either Party or any entity that controls, is controlled by or is
                under common control of either Party. The terms "subsidiary" and
                "holding company" shall have the meaning assigned thereto in
                Chapter 1 of the Companies Act, 2008, as amended, and (b) any
                entity that controls, is controlled by or is under common
                control of either Party. For the purposes of this definition,
                "control" means the possession, directly or indirectly, of the
                power to direct or cause the direction of the management and
                policies of the entity through the ownership of voting
                securities representing 50% (fifty percent) plus 1 (one) of the
                possible votes;
              </li>

              <li className="my-1 pt-1  pb-4">
                “Applicable Law(s)” means all law applicable in the Republic of
                South Africa, and includes any present or future constitution,
                decree, judgment, legislation, order, ordinance, regulation,
                statute, treaty, directive, rule, guidance or code and/or
                practice note issued by any relevant authority;
              </li>
              <li className="my-1 pt-1  pb-4">
                “Bank Account” means a transactional bank account held by a
                reputable financial institution in the Republic of South Africa
                in the name of the Vendor, approved by Touchsides and linked to
                the Touchsides Platform applicable to the Vendor;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Device” means a desktop terminal that is delivered and
                installed at the Premises each point-of-sale device which is a
                management system enabling Vendors to record sales of Products
                electronically;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Intellectual Property” means all intellectual property rights
                including patents, trademarks, service marks, designs, design
                rights, methodologies, business processes, software, ideas,
                copyright (including that in the designs and computer software),
                source codes, inventions, trade secrets, including the
                Touchsides Platform, of which Touchsides and/or its Affiliates
                is/are the owner from time to time, whether these are registered
                or not and rights to apply for protection of any of the same;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Personal Information” means information about an identifiable,
                natural or juristic person, including but not limited to,
                information about race, gender, marital status, nationality,
                ethnic or social origin, sexual orientation, age, physical or
                mental health, religion, belief, disability, language, birth,
                education, identity number, telephone number, email, postal or
                street address, location through your device’s GPS;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Products” means the products sold to the Vendor’s customers;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Rewards Program” means rewards earned by the Vendor and/or its
                customers in the form of loyalty promotions and Products by
                virtue of being part of the Rewards Program;
              </li>

              <li className="my-1 pt-1 pb-4">
                Touchsides”, “us”, “we” and “our” Touchsides S.A Proprietary
                Limited with registration number 2007/007727/07 and its
                registered address at Lesaka Building, 5 Century City Drive,
                Century City, 7441;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Touchsides Platform” means the Device, Touchsides, Touchsides’
                Rewards Program and any other mobile or desktop applications
                owned, licensed and/or operated by Touchsides, or any one of
                these functionalities operating individually;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Transaction” means any transaction recorded by the Vendor using
                the Touchsides Platform;
              </li>

              <li className="my-1 pt-1 pb-4">
                “Vendor”, “you” or “your” means the vendor, being any and all
                users and/or subscribers of the Touchsides Platform;
              </li>

              <li className="my-1 pt-1 pb-4">Reference to:</li>
              <ol className="custom-ol-style my-1">
                <li className="my-1 pt-1 mx-2 pb-4">
                  one gender includes all the genders;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  the singular form of a word includes the plural;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  the plural form of a word includes the singular;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  a law or regulation is a reference to that law or regulation
                  as amended from time to time.
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  the words “include”, “including” and “in particular” are by
                  way of example only and shall not limit the generality of any
                  preceding words; and
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  the words “other” and “otherwise” shall be interpreted as
                  widely as possible and will not be limited by any preceding
                  words.
                </li>
              </ol>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Use of the Touchsides Platform
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                In accessing and using the Touchsides Platform, you must not:
              </li>
              <ol className="custom-ol-style my-1">
                <li className="my-1 pt-1 mx-2 pb-4">
                  breach any Applicable Laws or such internal policies of
                  Touchsides;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  breach these Terms & Conditions or any other terms and
                  conditions which govern your relationship with Touchsides;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  upload material to the Touchsides Platform which is
                  threatening, abusive, defamatory, obscene, indecent or
                  otherwise inappropriate;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  market your product(s) in a misleading, fraudulent way, or in
                  a manner that may reasonably be regarded as deceptive or which
                  may bring the name of Touchsides and/or any of its Affiliates
                  into disrepute;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  send any communication or submit any information to Touchsides
                  that is false, misleading, deceptive or which may bring the
                  name of Touchsides and/or any of its Affiliates into
                  disrepute;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  infringe the rights of Touchsides, its Affiliates and/or any
                  third party;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  disassemble, decompile, reverse-engineer, or create derivative
                  works based on the whole or any part of the Touchsides
                  Platform;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  distribute spam, chain letters, or promote pyramid schemes;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  distribute viruses or other harmful technology with may cause
                  harm to Touchsides, the Touchsides Platform, its Affiliates or
                  any other third-party;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  attempt to impose or contribute to imposing an unreasonable
                  load on Touchsides’ infrastructure or interfere with the
                  proper working of the Touchsides Platform;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  copy, modify, or distribute Touchsides, or any other person’s,
                  content without their consent;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  harvest or otherwise collect information about other users,
                  without their consent;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  copy, modify, or distribute rights or content from the
                  Touchsides Platform;
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  bypass any security measures on the Touchsides Platform; and{" "}
                </li>

                <li className="my-1 pt-1 mx-2 pb-4">
                  carry out or continue any conduct that may constitute any
                  fraudulent activities, money laundering, terrorist financing,
                  round-tripping, corruption or any other criminal activity,
                  illegal use, or abuse of the Touchsides System.
                </li>
              </ol>
              <li className="my-1 pt-1 pb-4">
                You agree to defend Touchsides against any claims or legal
                proceedings against us as a result of you breaching any part of
                these Terms & Conditions.
              </li>
              <li className="my-1 pt-1 pb-4">
                We may in our sole and absolute discretion have the right to
                reverse or suspend any Transaction, or suspected fraudulent
                transaction, performed on the Touchsides Platform (including the
                suspension and hold on any amounts owing to you). You will have
                no legal recourse against us and we will have the right to
                recover any and all costs incurred by us, as a result of such
                reversal or suspension from you. Furthermore, your access to the
                Touchsides Platform may be terminated with immediate effect for
                any reason, including but not limited to your failure to comply
                with clause 3.1 and/or fraud or suspected fraud and you may be
                reported to the relevant authorities for prosecution.
              </li>
              <li className="my-1 pt-1 pb-4">
                Transactions involving the following are prohibited on the
                Touchsides Platform:
                <ol className="custom-ol-style my-1">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    items, materials or substances which are illegal and/or
                    prohibited by Applicable Laws;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    weapons or ammunition of any kind;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    alcohol and/or tobacco products, without the appropriate
                    license/s;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    any item which may violate any person’s rights (including
                    intellectual property rights); and/or
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    any other items which Touchsides deems to be dangerous or
                    inappropriate, acting in our sole discretion.
                  </li>
                </ol>
                <li className="my-1 pt-1 pb-4">
                  We reserve the right to utilise location services for
                  acquiring geographical information relating to the Device. By
                  using and continuing to use the Touchsides Platform, the
                  Device or any one of the functionalities within the Touchsides
                  Platform, you expressly grant consent to our utilisation of
                  location services.
                </li>
                <li className="my-1 pt-1 pb-4">
                  We reserve the right to utilise all data collected by
                  Touchsides during the term of the appropriate agreement
                  between the Vendor and Touchsides. By using and continuing to
                  use the Touchsides Platform, the Device or any one of the
                  functionalities within the Touchsides Platform, you expressly
                  grant consent to our utilisation of the data collected.
                </li>
              </li>
            </ol>

            <li>
              <span className="3xl:text-xl md:text-base ml-1 font-bold">
                Touchsides Device
              </span>
              <ol className="custom-ol-style m">
                <li className="my-1 pt-1 pb-4">
                  The Vendor warrants that it has made itself familiar with
                  these Terms & Conditions, made available upon signing up, as
                  amended from time to time, and confirms that its continued use
                  of the Device constitutes its acceptance thereof and agrees to
                  be bound thereby.
                </li>
                <li className="my-1 pt-1 pb-4">
                  <u>
                    Terms and Conditions Specific to the Rental of a Touchsides
                    Device
                  </u>
                  <p className="mx-2 my-1 pt-1">Delivery and installation</p>
                </li>
                <ol className="custom-ol-style my-1">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Vendor will obtain all necessary approvals and consents,
                    licenses, certificates and exemptions including any consent
                    to install and operate the Device and to display any
                    advertising and signage in respect of the Device from the
                    landlord where the premises are leased and /or from any
                    local authority or regulator, where applicable prior to
                    installation of the Device.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides will deliver the Device to the premises or
                    Vendor's selected delivery address, at the Vendor's risk and
                    cost, which costs are set out in the onboarding
                    documentation.
                    <p className="my-1 pt-1">
                      Risk and Ownership of the Device{" "}
                    </p>
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Notwithstanding anything to the contrary contained in the
                    Agreement entered into between Touchsides and the Vendor,
                    Touchsides will, at all times, own and remain the owner of
                    the Device, except where the Device has been sold by
                    Touchsides to the Vendor (as contemplated hereinbelow).{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Vendor will keep the Device free of all liens and
                    encumbrances.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Should the Device become damaged, the Vendor will notify
                    Touchsides immediately of such damage. The Vendor will
                    thereafter within 24 (twenty-four) hours, give Touchsides
                    written details of any such damage. Touchsides will best
                    endeavour to repair or replace the damaged Device.
                    Touchsides has the discretion not to repair or charge a
                    replace value for the Device should the damage be as a
                    result of the Vendor’s negligence or wilful misconduct.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Should the Device become lost or stolen, the Vendor will
                    notify Touchsides immediately of such loss or theft. The
                    Vendor will thereafter within 24 (twenty-four) hours, give
                    Touchsides written details of any such loss or theft.
                    Notwithstanding any clause to the contrary, the Vendor will
                    claim from his/her Insurance the value of the lost or stolen
                    terminal, which value will be used to reimburse Touchsides.
                    Touchsides will endeavour to replace the lost or stolen
                    terminal. For avoidance of any doubt, Touchsides will only
                    replace the lost or stolen terminal following the full
                    payment of the replacement value.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    In all cases, as regards any report concerning a theft,
                    loss, or case of damage, Touchsides will provide the Vendor
                    with a reference number, which must be quoted by the Vendor
                    in all and any subsequent follow up communications with
                    Touchsides. Failure to quote the reference number will
                    result in Touchsides not being able to provide the Vendor
                    with any feedback relating to the matter.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Once Touchsides has received all details from the Vendor as
                    regards the loss or damage, Touchsides, in its sole
                    discretion, will use its reasonable efforts to repair and/or
                    to replace the Device within a reasonable period.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Notwithstanding anything to the contrary contained in this
                    Agreement, where the Device has been previously lost, stolen
                    or damaged (in other words on more than one occasion), and
                    Touchsides has received notification from the Vendor that
                    the Device has been lost, damaged or stolen once again,
                    Touchsides will at its sole and absolute discretion decide
                    whether it will repair, replace or re-install the Device at
                    the Premises. Should Touchsides ascertain that the Vendor is
                    a high risk Vendor, and accordingly elect not to replace,
                    re-install or repair the Device and accordingly elects as a
                    result to terminate the Agreement, Touchsides will notify
                    the Vendor in writing, within a 10 (ten) Day period, of such
                    election and decision and the reasons therefore and the
                    Agreement will thereafter be deemed to have come to an end,
                    this election being without prejudice to any rights which
                    Touchsides may have in consequence of its election to
                    terminate the Agreement.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    In the event of any re-installation, replacement or repair,
                    the Vendor will allow Touchsides all reasonable access to
                    the Premises during the Vendor’s ordinary business hours for
                    the purpose of installing or repairing the Device and the
                    provisions of this Agreement relating to the installation of
                    the Device will apply in the same manner (mutatis mutandis)
                    to the replacement, re-installation and/or uplifting of the
                    Device.
                    <p className="my-1 pt-1">Fees</p>
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Devices will be supplied to the Vendor on a rental
                    basis. The rental amount of the Device is set out in the
                    onboarding documentation.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Payment of the rental will be in arrears, on the selected
                    mandated date for the duration of the agreement by way of
                    debit order, Kazang Supplier Payment, or electronic
                    transfer. If the Vendor chooses the debit order option, the
                    Vendor expressly authorises Touchsides to process such
                    amount through the bank account as provided in the
                    onboarding documentation.
                    <p className=" my-1 pt-1">Relocation or Removal </p>
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Where the Vendor is forced to relocate and/or move from the
                    Premises, it will have to apply for and obtain written
                    permission from Touchsides before the Device can be
                    relocated, which application must be made in writing at
                    least 1 (one) month before the anticipated relocation or
                    removal of the Device.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    On receipt of the application Touchsides may in its sole and
                    absolute discretion, consent to the requested removal,
                    provided always that the removal is to a site or alternative
                    Premises which Touchsides has in writing confirmed is
                    suitable and acceptable for the installation of the Device.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides will have the right to attach any conditions to
                    any relocation and/or removal, of the Device including any
                    variation or adjustment of the fees and charges detailed
                    under this Agreement.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides will be the only party entitled to affect such
                    removal and relocation and any consequential installation,
                    which costs will be paid for by the Vendor, on demand and by
                    way of debit order, Kazang Supplier Payment, or electronic
                    transfer.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Agreement, save for the description of the new premises
                    and any changes to the fee and charges, will apply to any
                    relocation.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Any relocation or removal, which is not done in accordance
                    with this clause, will be a material breach of the
                    Agreement.
                    <p className="my-1 pt-1">
                      Consequences of Termination of the Agreement{" "}
                    </p>
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Subject to clause 18 of these Terms and Conditions, the
                    Vendor shall return to Touchsides the Device upon
                    termination of the Agreement. Should the Device not be
                    returned by the Vendor within 7 (seven) Days of written
                    request to do so, the Vendor will be liable to pay a
                    replacement fee for the Device.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Should Touchsides be required to collect the Device for
                    whatever reason, Touchsides will be allowed access to the
                    premises for the purposes of removing the Device, which will
                    be done at the cost of the Vendor and added to any damages
                    claim Touchsides may have, from the Vendor’s Premises:{" "}
                  </li>
                  <ol className="custom-ol-style my-1">
                    <li className="my-1 pt-1 mx-4 pb-4">
                      Vendor will be liable for a collection fee; and
                    </li>
                    <li className="my-1 pt-1 mx-4 pb-4">
                      Any damages incurred to the Premises as a result of the
                      removal will be for the Vendor’s account- Touchsides
                      expressly advising that it will not be liable for these,
                      if any, amounts.
                    </li>
                  </ol>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    All amounts stated above are exclusive of VAT and payable
                    within 7 (seven) Days of written request to do so.{" "}
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    In the event of the Agreement being terminated by the Vendor
                    as a result of a breach by Touchsides, the Vendor will allow
                    Touchsides access to the Premises for the purpose of
                    removing the Device, which will be done at Touchsides’s own
                    cost and expense.
                  </li>
                </ol>
                <li className="my-1 pt-1 pb-4">
                  <u>
                    Terms and Conditions specific to the purchase of a
                    Touchsides Device{" "}
                  </u>

                  <p className="mx-2 my-1 pt-1">Delivery</p>
                </li>
                <ol className="custom-ol-style my-1">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Vendor will obtain all necessary approvals and consents,
                    licenses, certificates and exemptions including any consent
                    to install and operate the Device and to display any
                    advertising and signage in respect of the Device from the
                    landlord where the premises are leased and /or from any
                    local authority or regulator, where applicable prior to
                    installation of the Device.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides will deliver the Device to the premises or
                    Vendor's selected delivery address, at the Vendor's risk and
                    cost, which costs are set out in the onboarding
                    documentation.
                    <p className="my-1 pt-1">Risk and Ownership</p>
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    All risk in and to the Device and related services shall
                    pass to the Vendor on delivery to the Vendor, however,
                    ownership shall remain vested in Touchsides until the full
                    purchase price has been paid to Touchsides.
                    <p className="my-1 pt-1">Fees </p>
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Devices will be supplied to the Vendor on a rental basis
                    until the full purchase price of the Device has been paid to
                    Touchsides. The purchase price of the Device is set out in
                    the onboarding documentation.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Payment of the rental will be in arrears, on the selected
                    mandated date of every month for the duration of the
                    agreement by way of debit order, Kazang Supplier Payments,
                    or electronic transfer. If the Vendor chooses the debit
                    order option, the Vendor expressly authorises Touchsides to
                    process such amount through the bank account as provided in
                    the onboarding documentation.
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Vendor will be liable to pay such other fees as may be
                    contemplated in the onboarding documentation, which fees
                    shall be due upon receipt of invoice, unless otherwise
                    provided in the onboarding documentation.
                    <p className="my-1 pt-1">Standard Warranty </p>
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides warrants that the Device will be free from
                    apparent defect as far as workmanship is concerned.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Notwithstanding the above, but at all times subject to the
                    provisions of the CPA, the warranty does not cover the
                    following:
                  </li>

                  <ol className="custom-ol-style my-2 mx-4">
                    <li className="my-1 pt-1 mx-2 pb-4">
                      cracked and/or broken screens
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">
                      instances where the Device has been tampered with (and the
                      warranty sticker is broken);
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">
                      instances where the cables are cut, damaged or faulty;
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">
                      broken clips on the Device’s cover;
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">
                      any visible damage to the Device that may impact the
                      device’s functionality;
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">liquid damage;</li>

                    <li className="my-1 pt-1 mx-2 pb-4">
                      broken or damaged USB/charging ports/pins;
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">
                      damaged battery due to tampering; and/or
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">
                      damaged, lost or stolen SIMs;
                    </li>

                    <li className="my-1 pt-1 mx-2 pb-4">If:</li>

                    <ol className="custom-ol-style my-2 mx-2">
                      <li className="my-1 pt-1 mx-2 pb-4">
                        a defect arises, other than those not covered by the
                        warranty, as contemplated in clause 4.3.8 above; or{" "}
                      </li>
                      <li className="my-1 pt-1 mx-2 pb-4">
                        the following occurs in relation to the Device:
                      </li>
                      <ol className="custom-ol-style my-2 mx-2">
                        <li className="my-1 pt-1 mx-2 pb-4">
                          normal wear and tear;
                        </li>

                        <li className="my-1 pt-1 mx-2 pb-4">
                          the printer breaks;
                        </li>
                        <li className="my-1 pt-1 mx-2 pb-4">
                          the touch screen is not operational and there is no
                          visible damage;
                        </li>
                        <li className="my-1 pt-1 mx-2 pb-4">
                          faulty batteries;
                        </li>
                        <li className="my-1 pt-1 mx-2 pb-4">
                          faulty charging base; and/or
                        </li>
                        <li className="my-1 pt-1 mx-2 pb-4">
                          faulty SIM and there is no visible damage,
                        </li>
                      </ol>
                      <p className="my-1 pt-1">
                        within 6 (six) months from date of delivery, and is
                        proven by way of a written report, then Touchsides will
                        replace the Device, with the Vendor to pay any amounts
                        that may be due in law, provided the device is in its
                        original packaging and returned to Touchsides at the
                        Vendor’s costs. After the expiry of the 6-month period,
                        the Vendor will be obligated to purchase a new device in
                        such instances.
                      </p>
                    </ol>
                  </ol>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides shall in no way be liable for any damage caused
                    to the Device through misuse, negligence or misconduct by
                    the Vendor.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    In the event of any damage to a device due to gross
                    negligence of the Vendor, such as but not limited to water
                    damage or a damaged screen, the Vendor may purchase a new
                    device, however Touchsides will not be required to fix the
                    device.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    The Vendor acknowledges that Touchsides does not offer
                    insurance products and, as such, the Device is not insured.
                    <p className="my-1 pt-1">
                      Warranties in terms of the Consumer Protection Act 68 of
                      2008
                    </p>
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides warrants that the Device is suitable for its
                    intended purpose, free from defects and will operate the way
                    it was designed if the Vendor carries out all of its
                    obligations in terms of the Agreement.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    If the Device is defective, Touchsides will replace the
                    Device subject to clause 4.3.7.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    If the Device has been accepted for return, the following
                    terms apply, subject to the Vendor being liable for
                    collection and/or delivery costs:
                    <ol className="custom-ol-style">
                      <li className="my-1 pt-1 mx-2 pb-4">
                        if the original packaging is unopened, there will be no
                        charge; and
                      </li>
                      <li className="my-1 pt-1 mx-2 pb-4">
                        if the Device is in its original condition and repacked
                        in the original packaging, a reasonable amount may be
                        charged for:
                      </li>
                      <li className="my-1 pt-1 mx-2 pb-4">
                        use of the Device; and
                      </li>
                      <li className="my-1 pt-1 mx-2 pb-4">
                        necessary costs associated with restoration of the
                        Device for restocking.
                      </li>
                    </ol>
                    <li className="my-1 pt-1 pb-4">
                      Touchsides will provide purchasers with the above remedies
                      only as far as is provided for in the CPA.
                      <p className="my-1 pt-1">
                        Return of Goods and Order Cancellation{" "}
                      </p>
                    </li>
                    <li className="my-1 pt-1 pb-4">
                      Touchsides is under no obligation to accept the return of
                      goods, which it may do so within its own discretion and
                      upon terms and conditions to be agreed upon between the
                      parties, except if the Vendor has a right in terms of any
                      consumer law to return goods.
                    </li>
                  </li>
                </ol>
              </ol>
            </li>
            <li>
              <span className="3xl:text-xl md:text-base ml-1 my-6 font-bold">
                The Rewards Program Account Registration and Opening
              </span>

              <ol className="custom-ol-style">
                <li className="my-1 pt-1 pb-4">
                  Touchsides may, from time to time, provide the Vendor with
                  rewards program customer loyalty cards (“Rewards Program
                  Card(s)”), whether branded under the ‘Hola Club’ brand, or
                  otherwise.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Rewards Program Cards, unless paid for by the Vendor,
                  shall remain the property of Touchsides, which Rewards Program
                  Cards the Vendor is required to keep safe.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Vendor shall be required to register all of its customers
                  on the Rewards Program and issue them with a Rewards Program
                  Card in accordance with these Terms and Conditions.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Rewards Program Card is to be used for purposes of a
                  registered customer earning the advertised and promoted
                  Rewards Program, provided that all purchased Products are
                  scanned by the Device and the Rewards Program Card is tapped
                  against the Device prior to the registered customer paying for
                  the Products.
                </li>
                <li className="my-1 pt-1 pb-4">
                  You may only issue the Rewards Program Card in accordance with
                  the Terms.
                </li>

                <li className="my-1 pt-1 pb-4">
                  The Rewards Program Card is not transferable.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Rewards Program Card is limited to one card per registered
                  customer and may only be used by the registered customer to
                  whom it has been issued. No secondary cards may be issued to
                  family members of the registered customer or other third
                  parties.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Rewards Program Card shall not be used as a credit, debit
                  or guarantee card, save with the consent of Touchsides, which
                  consent shall be at Touchsides’ sole discretion.
                </li>
                <li className="my-1 pt-1 pb-4">
                  In the event of a registered customer’s Rewards Program Card
                  being lost, damaged, or destroyed, you may issue the
                  registered customer with a new Rewards Program Card, provided
                  that immediately thereafter you shall inform Touchsides that
                  the registered customer’s Rewards Program Card has been lost,
                  damaged, or destroyed.
                </li>
                <li className="my-1 pt-1 pb-4">
                  Touchsides shall issue the Vendor with Rewards Program Cards
                  for use by the Vendor and its applicable personnel. It is the
                  responsibility of the Vendor to ensure that these Rewards
                  Program Cards are only utilised by appropriate Personnel and
                  are kept safe and secure.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Vendor is required to have all Products purchased by the
                  Vendor, scanned on a Device on the date of delivery, and to
                  tap the Rewards Program Card on said Rewards Program device.
                </li>
                <li className="my-1 pt-1 pb-4">
                  Should the Vendor fail to use the device daily or fail to meet
                  the minimum use requirements as determined at the sole
                  discretion of Touchsides from time to time, then Touchsides,
                  in its sole discretion, has the right to withdraw the Vendor’s
                  membership in the Rewards Program and remove the Devices and
                  any unused Rewards Program Cards from the Vendor.
                </li>
              </ol>
            </li>
            <li>
              <span className="3xl:text-xl md:text-base ml-1 font-bold">
                Rewards and Promotions
              </span>
              <ol className="custom-ol-style">
                <li className="my-1 pt-1 pb-4">
                  Purchases of designated promotional products, as determined by
                  Touchsides will entitle the Vendor to earn rewards under the
                  Rewards Program. The quantity, nature, and value of any such
                  Rewards Program shall vary depending on the promotional
                  product and shall be determined at the sole discretion of
                  Touchsides. Touchsides reserves the right to amend both the
                  list of promotional products and their associated Rewards
                  Program from time to time.
                </li>

                <li className="my-1 pt-1 pb-4">
                  The availability and terms of the Rewards Program will be
                  communicated at intervals determined by Touchsides, and all
                  such communications shall be at Touchsides’ sole discretion.
                </li>
                <li className="my-1 pt-1 pb-4">
                  In instances where free stock or merchandise is offered as a
                  reward, the Vendor must scan the corresponding free stock or
                  merchandise on the Device at the time of purchase to validate
                  the promotion and ensure receipt of the Rewards Program
                  Reward.
                </li>
                <li className="my-1 pt-1 pb-4">
                  Rewards Program that are either older than three (3) months or
                  that have exceeded their validity period, whichever occurs
                  first, may be deemed invalid. Failure to timely allocate or
                  deliver Rewards Program to customers may result in the Rewards
                  Program not being credited to the Vendor’s account. The
                  validity periods of Rewards Program are subject to change at
                  Touchsides’ sole discretion.
                </li>
                <li className="my-1 pt-1 pb-4">
                  Reward points hold no cash value, are not redeemable for cash
                  unless expressly provided by Touchsides, and may not be used
                  for the payment of any credit or debt.
                </li>
                <li className="my-1 pt-1 pb-4">
                  Rewards Program shall only apply to products on promotion and
                  cannot be backdated.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Vendor agrees that Rewards Program will be settled in a
                  manner determined solely by Touchsides.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Vendor waives any right to claim compensation of any
                  nature relating to the Rewards Program (including damages,
                  expenses, costs or any other losses, including claims arising
                  out of any fraudulent activity by any of your personnel) from
                  Touchsides, Touchsides’ personnel and Touchsides’
                  shareholders.{" "}
                </li>
                <li className="my-1 pt-1 pb-4">
                  Where a promotion offers an instant price discount to a
                  shopper or customer, the Vendor’s corresponding reward will be
                  the difference between the recommended retail selling price
                  and the promotion price. The Vendpr will only redeem the
                  reimbursement at the end of the promotion and after there has
                  been verification of stock sold.{" "}
                </li>
                <ol className="custom-ol-style ">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Each price discount promotion would have its own specific
                    terms and conditions as provided by a brand owner in order
                    to be able to claim your reward (s). The terms and
                    conditions will include the duration of the promotion (s),
                    reward (s) and entry mechanisms.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Each price discount promotion will be added to the Device, 4
                    weeks before the launch of the power promotion. The Vendor
                    will be able to see all the terms and conditions that apply
                    to that promotion. Should the Vendor not wish to participate
                    in a specific power promotion, it may select “OPT OUT” on
                    the Device screen.
                  </li>
                </ol>
                <li className="my-1 pt-1 pb-4">
                  The Vendor may not:
                  <ol className="custom-ol-style ">
                    <li className="my-1 pt-1 mx-2 pb-4">
                      redeem any Rewards Program for Products that are not on
                      promotion.
                    </li>
                    <li className="my-1 pt-1 mx-2 pb-4">
                      issue Rewards Program to any customer if the promotion has
                      expired.
                    </li>
                    <li className="my-1 pt-1 mx-2 pb-4">
                      sell, barter or exchange Rewards Program.
                    </li>
                  </ol>
                </li>
                <li className="my-1 pt-1 pb-4">
                  All Products purchased from the Vendor is required to be
                  scanned through Device, in order for any Rewards Program to be
                  allocated and redeemed.
                </li>
                <li className="my-1 pt-1 pb-4">
                  In the event of any fraudulent activity by the Vendor or its
                  personnel, such Vendor will be disqualified from a promotion
                  and will not qualify to redeem a reward.
                </li>
              </ol>
            </li>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Membership and Duration
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                The Rewards Program shall commence immediately upon the date of
                signature of the appropriate written agreement entered into
                between the Vendor and Touchsides, or the installation of any
                Device at the Vendor’s outlet, whichever occurs first.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Rewards Program will operate indefinitely and will continue
                until you are notified to the contrary.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides reserves the right to withdraw or cancel your
                membership to the Rewards Program should you breach any of the
                Terms at any time.
              </li>
              <li className="my-1 pt-1 pb-4">
                Should the Rewards Program cease to operate, all rewards not
                redeemed by the date of termination, will be forfeited and
                neither the Vendor nor the affected registered customers, will
                have a claim against Touchsides as a result thereof.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides may discontinue the Rewards Program or any service
                offering, or may change the nature, features, functions, scope,
                or operation of the Rewards Program or any service offering, at
                any time and from time to time at its sole discretion.
              </li>
              <li className="my-1 pt-1 pb-4">
                Upon termination of your membership in the Rewards Program:
              </li>
              <ol className="custom-ol-style mx-4">
                <li className="my-1 pt-1 pb-4">
                  Touchsides will attend to remove all Devices, marketing
                  material and other documentation and information in whatever
                  form captured or stored relating to the appropriate written
                  agreement entered into between the Vendor and Touchsides,
                  including any unused Rewards Program Cards and Rewards Program
                  Cards issued to the Vendor.
                </li>
                <li className="my-1 pt-1 pb-4">
                  The Vendor shall, immediately upon termination of your
                  membership:
                  <ol className="custom-ol-style">
                    <li className="my-1 pt-1 pb-4">
                      cease to promote, market or advertise the Rewards
                      Programme in any manner whatsoever and cease to make use
                      of any of the intellectual property, approved marks,
                      information, documentation, Devices, marketing material
                      and other documentation or information belonging to
                      Touchsides or relating to the agreement between the
                      Parties, in your possession and control, and will bear any
                      and all costs associated with doing so;
                    </li>
                    <li className="my-1 pt-1 pb-4">
                      cease to issue any Rewards Program Cards;
                    </li>
                    <li className="my-1 pt-1 pb-4">
                      not allow any Rewards Program Cards to be used for any
                      other purpose whatsoever;
                    </li>
                    <li className="my-1 pt-1 pb-4">
                      take all reasonable steps to notify its registered
                      customers:
                      <ol className="custom-ol-style">
                        <li className="my-1 pt-1 mx-2 pb-4">
                          of the termination of the Rewards Programme in the
                          outlet;
                        </li>
                        <li className="my-1 pt-1 mx-2 pb-4">
                          that the customers remain a part of the Rewards
                          Programme;
                        </li>
                        <li className="my-1 pt-1 mx-2 pb-4">
                          that the rewards earned by the customers remain valid
                          until such time as they are terminated in terms of the
                          customer terms and conditions; and
                        </li>
                        <li className="my-1 pt-1 mx-2 pb-4">
                          the customers may continue to make use of their
                          Rewards Program Cards at other outlets,
                        </li>
                      </ol>
                      <li className="my-1 pt-1 pb-4">
                        permit the collection by Touchsides of all unused and/or
                        uncollected Rewards Program Cards.
                      </li>
                    </li>
                  </ol>
                </li>
              </ol>
              <li className="my-1 pt-1 pb-4 last">
                Touchsides may, for any reason, decline to make the Rewards
                Program application available on the Vendor’s Device and are not
                obliged to give a reason for declining your application.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                Should it be applicable, we reserve the right to carry out
                checks (including but not limited to checks with credit
                reference agencies) for the purposes of verifying your identity
                and credit status and so we can comply with our anti-money
                laundering and know-your-client obligations. By agreeing to
                these terms, you agree to our doing so.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Suspension of / Closing Your Rewards Program account:
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                You may close your Rewards Account, without charge, at any time
                by contacting 010 312 5105 or
                emailing{" "}
                <a href="mailto:info@holaclub.co.za">
                  {" "}
                  <font color="blue">info@holaclub.co.za</font>
                </a>{"."}{" "}
                We may close your Rewards Account without reason at any time on
                not less than 5 (five) days’ notice to you. 
              </li>
              <li className="my-1 pt-1 pb-4">
                We may, from time to time, suspend (in whole or in part) the
                operation of any Rewards Program account and / or your use of
                your Rewards Program account if:
              </li>
              <ol className="custom-ol-style mx-4">
                <li className="my-1 pt-1 pb-4">
                  we suspect your Rewards Program account is being used in
                  connection with unauthorised, fraudulent or unlawful
                  activities;
                </li>
                <li className="my-1 pt-1 pb-4">
                  we suspect that the security of your Rewards Program account
                  has been compromised or suspect an unauthorised transaction;
                </li>
                <li className="my-1 pt-1 pb-4">
                  we are required to do so by law, court order or the
                  instructions of a regulator;{" "}
                </li>
                <li className="my-1 pt-1 pb-4">
                  you breach any of these terms; or{" "}
                </li>
                <li className="my-1 pt-1 pb-4">
                  as otherwise set out in these terms. {" "}
                </li>
              </ol>
              <li className="my-1 pt-1 pb-4">
                We may, without prior notice, immediately close your Rewards
                Program account if:
                <ol className="custom-ol-style mx-4">
                  <li className="my-1 pt-1 pb-4">
                    you no longer satisfy the minimum eligibility criteria set
                    out above;
                  </li>
                  <li className="my-1 pt-1 pb-4">
                    you use the Rewards Program account in connection with
                    fraudulent or unlawful activities;
                  </li>
                  <li className="my-1 pt-1 pb-4">
                    you use the Rewards Program account or any Touchsides
                    property and/or email addresses to make representations or
                    announcements that are slanderous, defamatory, fraudulent,
                    obscene, profane, indecent, vulgar, repulsive or offensive;
                    or
                  </li>
                  <li className="my-1 pt-1 pb-4">
                    you breach any of the Terms & Conditions.{" "}
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4">
                If you have breached these terms, we may take such additional
                action as we deem appropriate including by:
              </li>
              <ol className="custom-ol-style mx-4">
                <li className="my-1 pt-1 pb-4">issuing a warning to you;</li>
                <li className="my-1 pt-1 pb-4">
                  commencing legal proceedings against you for recoverable loss
                  and damage resulting from the breach (including reimbursement
                  of all sums paid if you have used your Rewards Program account
                  in a fraudulent manner; and/or
                </li>
                <li className="my-1 pt-1 pb-4">
                  disclosing relevant information to law enforcement
                  authorities.
                  <p className="my-1 pt-1">
                    The actions described above are not limited and we may take
                    any other action we deem appropriate.{" "}
                  </p>
                </li>
              </ol>
              <li className="my-1 pt-1 pb-4">
                Upon closure of your Rewards Program account, for whatever
                reason:
              </li>
              <ol className="custom-ol-style mx-4">
                <li className="my-1 pt-1 pb-4">
                  all rights granted to you under these terms will cease
                  immediately (but this will not affect any rights or remedies
                  which either you or we may have in respect of anything that
                  takes place before your Rewards Program account is closed);
                  and
                </li>
                <li className="my-1 pt-1 pb-4">
                  you must discontinue promptly all use of your Rewards Program
                  account.{" "}
                </li>
              </ol>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Authorised / Unauthorised use of the Touchsides Platform
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                You are the only authorised user of your accounts and/or
                applications registered to your name, within the Touchsides
                Platform. You are not permitted to allow any other person to use
                your accounts within the Touchsides Platform.
              </li>
              <li className="my-1 pt-1 pb-4">
                You confirm that if the correct user details (e.g. password,
                biometrics or code) are entered when any of your accounts within
                the Touchsides Platform are accessed, Touchsides will assume
                that you are the person accessing your account(s) and you will
                be responsible for such use and any Transactions related
                thereto.
              </li>
              <li className="my-1 pt-1 pb-4">
                You must notify Touchsides immediately if you discover or
                suspect that your account(s) has been compromised or suspect
                unauthorised use thereof. Until you do so, your account(s) will
                not be secure and you will be liable for the use of your
                account(s) and any Transactions related thereto. 
              </li>
              <li className="my-1 pt-1 pb-4">
                If Touchsides suspects that your account(s) has been
                compromised, or suspects unauthorised use of the Touchsides
                Platform, suspects fraudulent use or Transactions, or use in
                breach ofthese Terms & Conditions, Touchsides:
              </li>
              <ol className="custom-ol-style mx-4">
                <li className="my-1 pt-1 pb-4">
                  may communicate with you via your registered email address or
                  via other secure means agreed between you and Touchsides.
                  Touchsides may need to verify your identity as part of this
                  process.
                </li>
              </ol>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Warranty
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                The Vendor acknowledges and agrees to utilise the Touchsides
                Platform in good faith and to utilise the Touchsides Platform
                solely as set out herein.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor hereby warrants to and in favour of Touchsides that
                it has the legal capacity and has taken all necessary corporate
                action required to empower and authorise it to conclude the the
                appropriate agreement between the Vendor and Touchsides and
                these Terms and Conditions which constitute an agreement valid
                and binding and enforceable against the Vendor.
              </li>
              <li className="my-1 pt-1 pb-4">
                Where the Vendor breaches any of the warranties herein, and/or
                utilises the Touchsides Platform or any functionality therein,
                for any purpose other than as set out herein, or in a manner
                which contravenes these Terms & Conditions, its duty of good
                faith to Touchsides and/or Touchsides’s reputation, then
                Touchsides shall be entitled to, amongst other things:
              </li>
              <ol className="custom-ol-style mx-4">
                <li className="my-1 pt-1 pb-4">
                  suspend the operation of the Device;
                </li>
                <li className="my-1 pt-1 pb-4">
                  suspend and/or terminate the Vendor’s use of the Touchsides
                  Platform or any functionality therein.
                </li>
              </ol>
              <li className="my-1 pt-1 pb-4">
                Contravention of the Vendor’s duty of good faith to Touchsides
                and/or Touchsides’s reputation shall include circumstances
                where, regardless of whether the Vendor financially gains from
                his/her/its conduct, the Vendor:
              </li>
              <ol className="custom-ol-style mx-4">
                <li className="my-1 pt-1 pb-4">
                  contravenes any Applicable Law, Touchsides’s internal policies
                  and/or these Terms & Conditions;
                </li>
                <li className="my-1 pt-1 pb-4">
                  interferes with Touchsides’s or any third party’s rights;
                </li>
                <li className="my-1 pt-1 pb-4">
                  commits or is suspected of committing fraud or any other
                  suspicious activity, whether intentionally or negligently, and
                  whether or not proven guilty;
                </li>
                <li className="my-1 pt-1 pb-4">
                  commits any abuse, misuse, or unauthorised use of the goods
                  and/or services provided by Touchsides.
                </li>
              </ol>
              <li className="my-1 pt-1 pb-4">
                The Vendor warrants that the bank details provided for purposes
                of the Debit Order Mandate, are true and correct, are for bank
                accounts which are legitimate and not fraudulent, and that
                payments liquidated into that bank account are final and
                irrevocable. For the avoidance of doubt, Touchsides will not be
                liable for any erroneous payments to the Vendor’s bank account,
                nor for any damage, loss or the like arising from an erroneous
                payment, incorrect bank details and/or a fraudulent bank
                account.
              </li>
            </ol>
          </li>

          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Liability
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                To the fullest extent permitted by law, Touchsides disclaims and
                excludes all representations, warranties and guarantees, whether
                implied or express and whether arising by law, contract or a
                course of dealings between you and Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides does not guarantee the continuous or uninterrupted
                operability of the Touchsides Platform. There may be periods of
                downtime for maintenance and upgrade work (whether on a
                scheduled or unscheduled basis). Touchsides is not responsible
                for the security of information that you choose to communicate
                with Touchsides while it is being transmitted or for any data
                lost during transmission.
              </li>
              <li className="my-1 pt-1 pb-4">
                Subject to the above, in no event shall Touchsides, our agents,
                officers, employees or sub- contractors be liable to you for any
                loss or corruption of data, software or hardware; loss of
                anticipated savings; loss of profit or economic losses;
                indirect, special or consequential losses and any liability
                Touchsides does have for losses you suffer is strictly limited
                to losses that were reasonably foreseeable and, in any case,
                shall not exceed the sum of R10,000.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides will not be liable or responsible for any failure to
                perform, or delay in performance of, any of our obligations that
                is caused by any act or omission of a third party or events
                outside our reasonable control.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor acknowledges and agrees that it shall be liable for:
              </li>
              <ol className="custom-ol-style mx-2">
                <li className="my-1 pt-1 pb-4">
                  the risk of any loss or damage, suffered as a result of using
                  the Touchsides Platform and which loss is occasioned as a
                  result of any theft, attempted theft, fraudulent tampering,
                  attempted tampering, fraud, attempted fraud, vandalism, theft
                  of and/or attempts to access, intercept and steal personal
                  details and /or information or cash from the Vendor or any
                  third party;{" "}
                </li>

                <li className="my-1 pt-1 pb-4">
                  the risk of any loss or damage, suffered due to the theft of
                  the Vendor’s consumer’s banking details;{" "}
                </li>

                <li className="my-1 pt-1 pb-4">
                  the risk of any loss or damage, suffered due to the theft or
                  interception of the banking details provided by the Vendor in
                  respect of Vendor Beneficiary Payments;
                </li>

                <li className="my-1 pt-1 pb-4">
                  risk of any loss or damage arising from or to the Device;{" "}
                </li>
                <li className="my-1 pt-1 pb-4">
                  the risk of any loss or damage, suffered by the Vendor, third
                  party or its consumers due to any unauthorised interception
                  and/or monitoring of information by a third party of the
                  Vendor, its consumers, or financial institution information;{" "}
                </li>
                <li className="my-1 pt-1 pb-4">
                  the risk of any loss or damage, suffered by the Vendor or its
                  consumers due to any failed, late or delayed Transactions,
                  save where either the Vendor or its consumer can show that any
                  loss or damage was due to the gross negligence of Touchsides
                  and/or a defect in the Touchsides Platform which has been
                  caused solely by Touchsides and subject always to the
                  provisions and defences permitted under the CPA;{" "}
                </li>

                <li className="my-1 pt-1 pb-4">
                  the risk of any damage in the case of a network breakdown,
                  system failure or equipment malfunction or damage to
                  facilities caused by power failures or similar events or loss
                  or damage caused by events beyond Touchsides’s control;
                </li>
                <li className="my-1 pt-1 pb-4">
                  the risk of any loss, damage or claims arising out of any
                  transactions other than electronic transactions or
                  transactions not prescribed in these Terms & Conditions,
                  suffered by the Vendor in the event that the Vendor processes
                  manual transactions.{" "}
                </li>
              </ol>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Indemnity
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                The Vendor must at all times comply with Applicable Laws and
                such Touchsides internal policies.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor indemnifies and holds Touchsides harmless against any
                loss or liability it may suffer as a result of non-compliance by
                you.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor indemnifies Touchsides against all risk, loss, damage
                or claims arising out of processing of Transactions and/or its
                use of the Touchsides Platform. For the avoidance of doubt, the
                Vendor assumes all liability and all risk associated with
                Transactions and its use of the Touchsides Platform, save where
                the Vendor can show that any loss or damage was due to the gross
                negligence of Touchsides and/or a defect in the Touchsides
                Platform which has been caused solely by Touchsides.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              No Assignment or Cession
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                The Vendor may not and shall not transfer or assign any or all
                of its rights or obligations under these Terms & Conditions
                without the prior written consent of Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor may not and shall not hand over the Device(s) or its
                use of the Touchsides Platform to any third party without the
                prior written consent of Touchsides.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              14. Data and Privacy
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                All data collected by Touchsides during the term of the
                appropriate agreement between the Vendor and Touchsides, will,
                at all times, be and remain the sole and exclusive property of
                Touchsides and the Vendor consents to Touchsides’ unfettered and
                unencumbered use of this data.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor may not use any data, or reports, made available by
                Touchsides during the term of the appropriate agreement between
                the Vendor and Touchsides, other than internally, and the Vendor
                acknowledges that such data, or reports, may not be shared with
                any third party whatsoever.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor is required to ensure that all information and data
                will only be disclosed to its employees for the purpose of
                allowing them to execute their obligations towards the Vendor or
                Touchsides.costs which are suffered by you in relation to the
                Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Vendor is required to respect the right to privacy of all
                Rewards Program Card holders and treat their personal
                information as confidential. The Vendor is not allowed to use
                personal information of Rewards Program members for its own
                purposes or access their data without any clear business need to
                do so and/or instruction from Touchsides. Should the Vendor
                become aware of any breach of personal information of Rewards
                Program Card holders, the Vendor is required to report such to
                Touchsides promptly after becoming aware of it.
              </li>
              <li className="my-1 pt-1 pb-4">
                The obligation to protect and not disclose any data to third
                parties remains effective during the term of your agreement with
                Touchsides and continues for a further five (5) years after the
                termination of rights and obligations in terms of your agreement
                with Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                We may collect and use your own personal information for the
                purposes as set out in the Rewards Program Outlet Owner Privacy
                Policy.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Personal Information
            </span>

            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                By agreeing to these Terms & Conditions, you hereby give
                Touchsides consent to process your Personal Information as
                provided for herein for the purpose of Touchsides performing the
                obligations related to the product offering. The processing of
                your Personal Information shall be done in accordance with the
                Privacy Policy contained on Touchsides’{" "}
                <a href="https://www.holaclub.co.za/privacypolicy/outletprivacypolicy">
                  <font color="blue">
                    https://www.holaclub.co.za/privacypolicy/outletprivacypolicy
                  </font>
                </a>{" "}
                as updated from time to time.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides as well as its as Affiliates wishes to send you
                information by means of electronic communication about the
                “Services” (as defined in the Privacy Policy) offered by the
                Touchsides and its Affiliates, including cash management, card
                payments, prepaid solutions and access to business funding. By
                providing your consent hereto, you further give consent for
                Touchsides to share your contact details with its Affiliates.
              </li>
            </ol>
          </li>
          <li className="my-1 pt-1">
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Intellectual Property
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1">
                All Intellectual Property will be the sole and exclusive
                property of Touchsides. You acknowledge, and warrant in favour
                of Touchsides, that you do not, and will not acquire, any right,
                title, or interest in and to the Intellectual Property.
              </li>
              <li className="my-1 pt-1 pb-4">
                You will not use the Intellectual Property except as expressly
                set out in these Terms & Conditions. You may not modify, sell,
                copy, sub-license or allow third-party access to, any of the
                Intellectual Property.
              </li>
              <li className="my-1 pt-1 pb-4">
                You must ensure that all promotional and advertising material as
                may be provided by Touchsides to you from time to time, is
                always displayed in accordance with Touchsides’ directions and
                requirements.
              </li>
            </ol>
          </li>
          <li className="my-1 pt-1">
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Breach
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1">
                The Vendor shall be deemed to be in breach of these Terms &
                Conditions if:
              </li>
              <ol className="custom-ol-style mx-2">
                <li className="my-1 pt-1 mx-2 pb-4">
                  the Vendor breaches any of the terms of these Terms &
                  Conditions;
                </li>
                <li className="my-1 pt-1 mx-2  pb-4">
                  any judgment or order is made against the Vendor;
                </li>
                <li className="my-1 pt-1 mx-2 pb-4">
                  a creditor attaches or takes possession of, or a distress,
                  execution, sequestration or other similar process for the
                  enforcement of creditors’ rights is levied or enforced upon or
                  against any material part of the assets, rights or income of
                  the Vendor; and/or
                </li>
                <li className="my-1 pt-1 mx-2 pb-4">
                  the Vendor becomes insolvent or stops or suspends payment of,
                  or admits inability to pay, its debts generally as and when
                  they fall due or is unable to pay its debts as and when they
                  fall due or commences negotiations with its creditors
                  generally with a view to the general readjustment or
                  re-scheduling of all or part of its indebtedness to such
                  creditors or proposes or enters into any composition or other
                  arrangement for the benefit of its creditors generally,or
                  proceedings are commenced in relation to the Vendor under any
                  Applicable Law, internal policies, regulation or procedure
                  relating to the reconstruction or readjustment of debts.{" "}
                </li>
              </ol>
            </ol>
          </li>
          <li className="my-1 pt-1 pb-4">
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              Termination and Suspension
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1">
                Touchsides is entitled to cancel, terminate, or suspend your use
                of the Touchsides Platform in respect of all Transactions or
                selected types of transactions immediately, or any combination
                of these actions, as may be permissible in law, without
                prejudice to any of our other rights (that is, without barring
                or limiting any future action), if:
              </li>
              <ol className="custom-ol-style">
                <li className="my-1  mx-2 pt-1 pb-4">
                  you have breached any of the terms of these Terms &
                  Conditions, or the provisions of any other agreement between
                  us;
                </li>
                <li className="my-1  mx-2 pt-1 pb-4">
                  you fail and/or refuse to abide by the terms of these Terms &
                  Conditions, or other instructions from Touchsides;
                </li>
                <li className="my-1  mx-2 pt-1 pb-4">
                  we have reason to believe that the Touchsides Platform has
                  been or is likely to be misused;
                </li>
                <li className="my-1  mx-2 pt-1 pb-4">
                  we suspect any illegal and/or fraudulent use of the Touchsides
                  Platform and/or its functionalities;
                </li>
                <li className="my-1  mx-2  pt-1 pb-4">
                  you have provided us with false or inaccurate information;
                </li>
                <li className="my-1  mx-2 pt-1 pb-4">
                  we are required to, by law, to do so; or
                </li>
                <li className="my-1  mx-2 pt-1 pb-4">
                  if we need to protect our interests or the interests of a
                  third party.
                </li>
              </ol>
              <li className="my-1 pt-1 pb-4">
                Touchsides reserves the right to terminate or suspend your
                access to and use of the Touchsides Platform upon written
                notice, without any liability to you or any third party.
              </li>
            </ol>
            <li className="my-1 pt-1">
              <span className="3xl:text-xl md:text-base ml-1 font-bold">
                Changes to these Terms & Conditions
              </span>
            </li>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Touchsides reserves the right to make changes to these Terms &
                Conditions as and when Touchsides deems it necessary in its sole
                discretion. {" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides will notify you of any change(s), including details
                of when any such changes will come into effect.  It is your
                responsibility to keep up to date and comply with the latest
                version of this document.
              </li>
              <li className="my-1 pt-1 pb-4">
                Subject to any statutory notice period, you will be deemed to
                have accepted the change(s) if (a) you continue to use the
                Touchsides Platform after the date specified for the change; or
                (b) you do not terminate your use of the Touchsides Platform
                before the date specified for the change.
              </li>
            </ol>
            <li className="my-1 pt-1">
              <span className="3xl:text-xl md:text-base ml-1 font-bold">
                General
              </span>
            </li>
            <ol className="custom-ol-style pb-4">
              <li className="my-1 pt-1 pb-4">
                If Touchsides fails to enforce any of its rights hereunder, that
                does not result in a waiver of that right. {" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                If any provision of these Terms & Conditions is found to be
                unenforceable, all other provisions shall remain unaffected.{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                These Terms & Conditions may not be varied by the Vendor except
                with Touchsides’ prior express written consent.{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                These Terms & Conditions and any document expressly referred to
                in therein represents the entire agreement between you and
                Touchsides in relation to the subject matter of these Terms &
                Conditions. Touchsides is required by law to advise you that the
                contract formed herein may be concluded in the English language
                only and that no public filing requirements, if applicable,
                apply.  {" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                Upon request, Touchsides will provide you with a copy of these
                Terms & Conditions or any part of the information contained
                herein. You can download these Terms & Conditions from
                Touchsides’ website at any time.{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                No third party has the right to enforce any terms of these Terms
                & Conditions.{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                The laws of the Republic of South Africa shall govern as to the
                interpretation, validity and effect of these Terms & Conditions
                and the use of the Touchsides Platform notwithstanding your
                domicile, residence or physical location.
              </li>
              <li className="my-1 pt-1 pb-4">
                You hereby consent and submit to the non-exclusive jurisdiction
                of the courts of the Republic of South Africa in any action or
                proceeding instituted under or related to these Terms &
                Conditions.{" "}
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default OutletOwnerTerms;
