import React from 'react';
import { useLocation } from 'react-router-dom';

const CustomerTerms = () => {
  const { pathname } = useLocation();

  const getFlexStyle = () => {
    return pathname === '/termsandconditions/customerterms'
      ? 'flex flex-col self-center mx-auto justify-center mt-12'
      : 'flex flex-col self-center mx-auto';
  };

  const getWidthStyle = () => {
    return pathname === '/termsandconditions/customerterms'
      ? 'self-center 3xl:w-1/2 mx-auto justify-center md:w-9/12'
      : 'self-center w-full mx-auto text-left';
  };

  const getTitleStyle = () => {
    return pathname === '/termsandconditions/customerterms'
      ? 'text-primary text-2xl font-bold 3xl:pb-24 pt-24 md:pb-12 text-center'
      : ' text-primary text-xl font-bold self-center text-center mb-8';
  };

  const getHeadingStyle = () => {
    return pathname === '/termsandconditions/customerterms'
      ? '3xl:text-xl md:text-base font-bold'
      : 'text-base font-bold';
  };

  const getParagraphStyle = () => {
    return pathname === '/termsandconditions/customerterms'
      ? 'text-base pt-1 pb-4'
      : 'text-sm pt-1 pb-4';
  };

  const getListStyle = () => {
    return pathname === '/termsandconditions/customerterms'
      ? ' text-base pt-1 pb-4 list-disc'
      : ' text-sm pt-1 pb-4 list-disc';
  };
  return (
    <div className={getFlexStyle()}>
      <div className={getWidthStyle()}>
        <h1 className={getTitleStyle()}>Customer Terms and Conditions</h1>

        <h2 className={getHeadingStyle()}> INTRODUCTION</h2>
        <p>
          <p className={getParagraphStyle()}>
            These terms and conditions of use (the "Terms") govern your (“you”,
            “your” or “user”) registration, participation in, and use of the
            Hola Club Rewards Programme (“Rewards Programme”), and use of the
            Rewards Programme customer loyalty card (“Hola Club card”), which is
            owned and operated by Touchsides S.A. (Pty) Ltd ("Touchsides", "
            we", "us" or “our”).
          </p>
          <p className={getParagraphStyle()}>
            The Terms include and incorporate our privacy policy, attached
            herein (the “Privacy Policy”). Please read the Terms carefully and
            should you have enquiries, please contact us at +27 (10) 312 5105.
          </p>

          <h2 className={getHeadingStyle()}>ACCEPTANCE OF TERMS</h2>
          <p className={getParagraphStyle()}>
            By registering for the Rewards Programme and using your Hola Club
            Card you agree to be bound by these Terms. By registering or using
            the Rewards Programme, you warrant that you are 18 years of age or
            older and that you have the capacity to conclude contractual
            arrangements and to accept the Terms.
          </p>
          <p className={getParagraphStyle()}>
            If you do not agree to these Terms, you must not, and you are not
            permitted to register for, or use the Rewards Programme. If you
            disagree with the Terms at a later stage, after registering for
            and/or using the Rewards Programme, you must immediately stop
            participating in the Rewards Programme.
          </p>

          <h2 className={getHeadingStyle()}>REGISTRATION</h2>
          <p className={getParagraphStyle()}>
            In order to participate in the Rewards Programme and claim rewards
            relating to promotions run on the Rewards Programme (“Rewards”) you
            will need to obtain a unique Hola Club Card and then complete the
            registration process.
          </p>
          <p className={getParagraphStyle()}>
            You may register for the Rewards Programme via Unstructured
            Supplementary Service Data (“USSD”) or via website form.
          </p>
          <p className={getParagraphStyle()}>
            You are required to provide the following information in order to
            register for the Rewards Programme:
          </p>
          <ul className={getListStyle()}>
            <li>Full name and surname;</li>
            <li>Cell phone number;</li>
            <li>Gender;</li>
            <li>Date of birth;</li>
            <li>
              Rewards Programme customer loyalty card (“Hola Club card”) number;
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            For details on how we process your personal information please see
            our Privacy Policy. A successful Hola Club Card registration creates
            your unique profile that is linked to your Hola Club Card and your
            cell phone number. An SMS confirming your registration as a member
            of the Rewards Programme will be sent to you.
          </p>

          <h2 className={getHeadingStyle()}>
            CHANGES TO THE TERMS AND TO THE REWARDS PROGRAMME
          </h2>
          <p className={getParagraphStyle()}>
            We may, at any time, amend the Terms and may inform you of the
            changes. Without limiting the ways in which we may inform you of any
            changes to the Terms, we may notify you either by e-mail, SMS,
            WhatsApp, or by placing a notice on the Hola Club website. You are
            advised to review the Terms at regular intervals in order to ensure
            that you are satisfied with any amendments to the Terms. In the
            event that you disagree with any amendments to the Terms, you must
            immediately cease all use of the Rewards Programme and opt out of
            the Rewards Programme (refer to the Privacy Policy).
          </p>
          <p className={getParagraphStyle()}>
            We may continuously or from time to time update the Rewards
            Programme and we reserve the right to make changes on how we offer,
            provide and operate the Rewards Programme. We may, at our sole
            discretion, from time to time, change, vary or amend the form,
            appearance, functionality and content of the Rewards Programme,
            including through correction of inherent errors and improvements
            which result in additional functions or features and introducing new
            versions of the Rewards Programme incorporating new or enhanced
            functionality.
          </p>

          <h2 className={getHeadingStyle()}>
            YOUR INTERACTIONS WITH THIRD PARTIES ON THE REWARDS PROGRAMME
          </h2>
          <p className={getParagraphStyle()}>
            As far as the law allows, Touchsides shall not be liable for your
            interactions with any organisations and/or individuals including an
            outlet.
          </p>

          <h2 className={getHeadingStyle()}>HOLA CLUB CARD</h2>
          <p className={getParagraphStyle()}>
            An outlet will provide you with a Hola Club Card upon request
            (subject availability). Subject to the Consumer Protection Act of
            2008 and the Electronic Communications and Transactions Act of 2002,
            Touchsides shall not be responsible or liable for any loss or damage
            of any sort incurred as the result of any of your dealings or
            interactions with an outlet, any other third parties and/or the
            Rewards Programme).
          </p>

          <p className={getParagraphStyle()}>
            If there are any images or packaging of products on the Hola Club
            device, please note that these are provided by the outlet and are
            for illustrative purposes only. Touchsides cannot and does not
            warrant that the products displayed on the Hola Club device are
            accurately described or reflected.
          </p>

          <h2 className={getHeadingStyle()}>UTILISATION</h2>
          <p className={getParagraphStyle()}>
            Each Hola Club Card is unique and must only be used by you.
          </p>
          <p className={getParagraphStyle()}>
            The Hola Club Card is issued by, and remains the property of
            Touchsides. Touchsides may withdraw or cancel your Hola Club Card or
            take any other action it may deem appropriate if it suspects fraud,
            misconduct or unauthorised use of your Hola Club Card. The Hola Club
            Card shall become invalid once cancelled in such circumstances and
            you will not be able to redeem any stored Rewards on your Hola Club
            Card. If you fail to use your Hola Club card for a continuous period
            of 3 months, Touchsides reserves the right to terminate your
            membership with the Rewards Programme. Touchsides reserves the right
            to terminate your membership, if in sending promotional messages to
            you, Touchsides fails to contact you after 3 SMS attempts in a
            period of 4 weeks or such other number of attempts as Touchsides may
            determine.
          </p>

          <ul className={getListStyle()}>
            <li className="my-1">
              There is no registration fee and the Hola Club Card is free of
              charge.
            </li>
            <li className="my-1">
              You can only use your Hola Club Card at any participating outlet
              as described in these Terms.
            </li>
            <li className="my-1">
              We are not liable for lost, stolen or damaged Hola Club Cards.
            </li>
            <li className="my-1">
              We will (at our sole discretion) replace any lost, stolen or
              damaged Hola Club Card with a new one.
            </li>
            <li className="my-1">
              To report your Hola Club Card lost, stolen or damaged please
              contact us at +27 (10) 312 5105 and we will notify you how you may
              obtain your replacement Hola Club Card.
            </li>
            <li className="my-1">
              Hola Club Cards may not be sold, exchanged or used for any other
              purpose, except as specified in these Terms, without Touchsides’
              written permission.
            </li>
            <li className="my-1">
              Touchsides does not warrant or represent that your Hola Club Card
              will always be accepted at participating outlets.
            </li>
          </ul>

          <h2 className={getHeadingStyle()}>REWARDS</h2>
          <ul className={getListStyle()}>
            <li className="my-1">
              Rewards are issued by third parties (“Brand” or “Brands”) and not
              Touchsides. As such, Touchsides is never responsible for any
              Rewards and should you have any questions or complaints regarding
              a Reward, please notify the party running the promotion in
              question.
            </li>
            <li className="my-1">
              All promotions and Rewards are subject to any specific terms and
              conditions related to such promotion or Reward.
            </li>
            <li className="my-1">
              In order to earn Rewards you must: (i) have registered your Hola
              Club Card; (ii) have your purchased products scanned at the till
              point and tap your Hola Club Card on the Hola Club device prior to
              paying; and (iii) comply with the terms and conditions of the
              promotion, the Terms and any other terms stipulated by Touchsides
              or the Brand. Any purchases made without complying with the above
              will mean that you do not qualify for any Rewards.
            </li>
            <li className="my-1">
              Rewards are only valid for the duration of the particular
              promotion for which it was earned and expire immediately at the
              end of each promotion.
            </li>
            <li className="my-1">
              The Rewards cannot be exchanged and are non-transferable.
            </li>
            <li className="my-1">
              Rewards older than 3 months or beyond validity, whichever is
              earlier, may become invalid and expire. The validity periods of
              Rewards are subject to change from time to time at the sole
              discretion of Touchsides.
            </li>
            <li className="my-1">
              Airtime rewards can only be redeemed by members with a pre-paid or
              top-up account, and only on the following networks: MTN, Cell C,
              Telkom and Vodacom. These networks and criteria are subject to
              change from time to time at Touchsides’ sole discretion.
              Touchsides accepts no responsibility whatsoever for any Reward
              which is not received by you if your cellphone number linked to
              the Rewards Programme does not meet the above criteria. You
              accordingly waive any right to claim compensation under these
              circumstances.
            </li>
            <li className="my-1">
              Touchsides accepts no responsibility whatsoever for any Reward
              which is not received by you for any reason. You accordingly waive
              any right to claim compensation under these circumstances.
            </li>
          </ul>

          <h2 className={getHeadingStyle()}>TERMINATION BY US</h2>
          <p className={getParagraphStyle()}>
            You agree that Touchsides may, at its sole discretion, suspend or
            terminate your access to all or part of our services, including our
            website and the Rewards Programme with or without notice and for any
            reason, including, without limitation, breach of the Terms.
          </p>

          <h2 className={getHeadingStyle()}>TERMINATION BY YOU</h2>
          <p className={getParagraphStyle()}>
            You have the right to terminate your registration with the Rewards
            Programme at any time, without reason, by emailing us at
            STOP@holaclub.co.za. Refer to the Privacy Policy for other ways of
            terminating your registration with the rewards Programme.
          </p>

          <h2 className={getHeadingStyle()}>CONSEQUENCES OF TERMINATION</h2>
          <p className={getParagraphStyle()}>
            Where the registration is terminated for any reason, you will lose
            all your unused Rewards.
          </p>

          <p className={getParagraphStyle()}>
            <strong>Intellectual Property</strong> means all current and future
            intellectual property rights of any kind whatsoever and however
            embodied which may subsist or be capable of protection wheresoever
            in the world, including all patents, trademarks, service marks,
            design rights, present and future rights of copyright, source codes,
            rights in and to inventions, rights in and to trade secrets, rights
            in and to trade names, business names, domain names and logos, the
            right to keep information confidential and private, rights in and to
            know-how, rights in and to database (including rights of
            extraction), confidential information and all other intellectual
            property rights and rights of a similar character whether or not
            registered or capable of registration and all applications and
            rights to apply for protection of any of the same.
          </p>

          <p className={getParagraphStyle()}>
            All materials, texts, drawings, graphics, logos, icons and any data
            made available in relation to the Rewards Programme and the Hola
            Club Card (“materials”) are owned by Touchsides (or the Brand in
            relation to a promotion) and are therefore protected by both South
            African and international intellectual property laws. Accordingly,
            any unauthorised copying, reproduction, retransmission,
            distribution, dissemination, sale, publication, broadcast or other
            circulation or exploitation of the materials or any component
            thereof will constitute an infringement of such copyright and other
            intellectual property rights.
          </p>

          <h2 className={getHeadingStyle()}>RESTRICTIONS</h2>
          <p className={getParagraphStyle()}>
            You may not copy or duplicate the Touchsides Intellectual Property
            or any part thereof without Touchsides’ express prior written
            consent.
          </p>

          <p className={getParagraphStyle()}>
            All Intellectual Property residing in the Rewards Programme is the
            exclusive property of Touchsides and the rights thereto, including
            the rights in and to the compilation of data stored on the Rewards
            Programme shall remain vested in Touchsides.
          </p>

          <p className={getParagraphStyle()}>
            Touchsides is entitled to take all and any steps necessary,
            including the institution of legal proceedings, to protect any
            Intellectual Property residing in or emanating from the Rewards
            Programme, including any Intellectual Property non-exclusively
            licensed to Touchsides by a third party, without notice to or
            permission from you.
          </p>

          <h2 className={getHeadingStyle()}>
            PERMITTED USE OF THE REWARDS PROGRAMME AND WHAT YOU ARE NOT ALLOWED
            TO DO
          </h2>
          <p className={getParagraphStyle()}>
            You must notify us immediately if you suspect or discover any
            actual, threatened or suspected unauthorised use of the Rewards
            Programme. The Rewards Programme should only be used for the purpose
            as stipulated on the Rewards Programme and the Terms. Any conduct
            that violates the Terms, that is illegal, unlawful, and fraudulent
            or violates or infringes any rights, title or interest (including,
            but not limited to, any Intellectual Property rights) in or to the
            Rewards Programme is strictly prohibited.
          </p>

          <p className={getParagraphStyle()}>
            You may not utilize the Hola Club Card for commercial purposes
            without our prior written consent. You may not yourself or through a
            third party use the Hola Card for harmful, immoral, unlawful or
            illegal purposes. Touchsides reserves the right to refuse to accept
            a Hola Club Card or to limit the use of a Hola Club Card which it
            deems to have been tampered with, duplicated, damaged or which
            otherwise is suspected to be affected by fraud, misconduct or
            unauthorised use. You may not copy or duplicate the Touchsides
            Intellectual Property or any part thereof without express prior
            written consent.
          </p>

          <h2 className={getHeadingStyle()}>OWN RISK</h2>
          <p className={getParagraphStyle()}>
            You use the Hola Club Card and participate in the Rewards Programme
            at your own risk and we make no representations or warranties about
            it.
          </p>

          <p className={getParagraphStyle()}>
            As far as the law allows, the Rewards Programme and the
            illustrations of products and information on the Hola Club device
            are provided to you on an "as is" and "as available" basis and
            without any representation or warranty whatsoever, whether express,
            implied or statutory (including any implied warranties of
            reliability, fitness for any particular purpose, or exclusion of
            errors or inaccuracies). As far as the law allows, neither
            Touchsides, nor any of its affiliates, Brands, shall be liable for
            any damages that may result from your registration for, and use of,
            the Rewards Programme, including, but not limited to, any damages or
            loss resulting from any interruption, failure, delay or cessation of
            transmission to or from the Hola Club device; any defect, failure,
            fault and/or delay in connectivity to the internet.
          </p>

          <p className={getParagraphStyle()}>
            Touchsides makes no representation and gives no warranties or
            undertakings regarding (i) the operation, integrity, compatibility,
            availability or functionality of the Rewards Programme; or (ii) that
            the Hola Club device and accordingly the content thereon will be
            available or accessible at all times, be uninterrupted, timely,
            error-free, secure or free from destructive code, or meet your
            individual requirements.
          </p>

          <h2 className={getHeadingStyle()}>WARRANTIES AND INDEMNIFICATION</h2>
          <p className={getParagraphStyle()}>
            By using the Rewards Programme, you represent, warrant and undertake
            that at all times that you have the capacity to accept the Terms and
            that the Terms constitute an agreement valid and binding on you and
            enforceable against you in accordance with their terms. As far as
            the law allows, you indemnify Touchsides, its affiliates including
            their respective directors, shareholders, officers, employees,
            agents representatives and Brands and agree to hold us harmless from
            and against any and all loss, liability, costs or damages which we
            may suffer as a result of you infringing on or misusing any person's
            rights, including Intellectual Property rights; you failing to
            comply with the Terms; any deliberate or unlawful act that you
            commit, or your unlawful failure to act.
          </p>

          <p className={getParagraphStyle()}>
            You indemnify Touchsides, and its affiliates, including their
            respective employees, agents, representatives, officers, directors,
            shareholders and Brands, and hold them harmless against all losses,
            liabilities, costs, expenses, fines, penalties, damages and claims,
            and all related costs and expenses (including legal fees on the
            scale as between attorney and own client, tracing and collection
            charges, costs of investigation, interest and penalties), which you
            may sustain arising out of or in connection with registration with
            the Rewards Programme, your usage of the Rewards Programme,
            participation in the Rewards Programme, or termination of your
            membership in the Rewards Programme.
          </p>

          <p className={getParagraphStyle()}>
            You will defend, indemnify, and hold Touchsides, and its affiliates
            and their respective directors, shareholders, officers, employees,
            Brands, agents and representatives harmless from and against any
            third party suit, proceeding, assertion against any damages,
            judgments, liability, costs and expenses (including without
            limitation any reasonable attorneys’ fees) incurred arising from
            your material breach of these Terms, or your unauthorised use or
            misuse of the Rewards Programme.
          </p>
          <h2 className={getHeadingStyle()}>GOVERNING LAW</h2>
          <p className={getParagraphStyle()}>
            The Terms shall be governed by and construed in accordance with the
            laws of the Republic of South Africa without giving effect to any
            principles of conflict of law. You consent to exclusive jurisdiction
            of the High Court of South Africa in respect of any disputes arising
            in connection with the services referred to herein, or the Terms, or
            any matter related to or in connection therewith.
          </p>

          <h2 className={getHeadingStyle()}>ENTIRE AGREEMENT</h2>
          <p className={getParagraphStyle()}>
            The Terms and the Privacy Policy, set out the entire agreement
            between yourselves and us related to the subject matter hereof, and
            supersedes any and all prior terms, conditions, warranties and/or
            representations to the fullest extent permitted by law.
          </p>

          <h2 className={getHeadingStyle()}>DATA</h2>
          <p className={getParagraphStyle()}>
            Touchsides shall be entitled to make use of all the data you provide
            for purposes of providing the Rewards Programme and Rewards to you.
            For more information please see our Privacy Policy.
          </p>

          <h2 className={getHeadingStyle()}>ASSIGNMENT</h2>
          <p className={getParagraphStyle()}>
            Touchsides shall be entitled to cede, assign and delegate all or any
            of its rights and obligations in terms of the Terms.
          </p>

          <h2 className={getHeadingStyle()}>SEVERABLE</h2>
          <p className={getParagraphStyle()}>
            All provisions of the Terms are, irrespective of the manner in which
            they have been grouped together or linked grammatically, severable
            from each other. Any provision of the Terms which is or becomes
            unenforceable in any jurisdiction, whether due to voidness,
            invalidity, illegality, unlawfulness or for any reason whatsoever,
            shall, in such jurisdiction only and only to the extent that it is
            so unenforceable, be excluded from the agreement between us and the
            remaining provisions of the Terms shall remain in full force and
            effect.
          </p>

          <h2 className={getHeadingStyle()}>NO WAIVER</h2>
          <p className={getParagraphStyle()}>
            The failure by Touchsides to enforce any provision of the Terms
            shall not affect in any way its right to require performance of the
            provision at any time in the future, nor shall the waiver of any
            subsequent breach nullify the effectiveness of the provision itself.
          </p>

          <h2 className={getHeadingStyle()}>
            Touchsides S.A. Proprietary Limited details
          </h2>
          <ul className={getListStyle()}>
            <p className="pt-2">
              <strong>Full Name:</strong> Touchsides S.A. (Proprietary) Limited
            </p>
            <p>
              <strong>Physical Address:</strong> President Place 3rd Floor Cnr.
              Jan Smuts Avenue & Bolton Road, Rosebank, Johannesburg, South
              Africa 2196
            </p>
            <p>
              <strong>Telephone No.:</strong> +27 (10) 312 5105
            </p>
            <p>
              <strong>Website Address:</strong> www.touchsides.com
            </p>
            <p>
              <strong>Email Address:</strong> info@touchsides.com
            </p>
            <p>
              <strong>Registration No.:</strong> 2007/007727/07
            </p>
            <p>
              <strong>Country of incorporation:</strong> South Africa
            </p>
          </ul>
        </p>
        <h2 className={getHeadingStyle()}>CHANGE LOG AS AT JULY 2021</h2>
        <ul className={getListStyle()}>
          <li className="my-1">
            <b>Section 3: Registration</b>
            <li className="my-1 pb-4">
              Registration is no longer done on the device instore. Registration
              can now be done via USSD or via website form. The information
              collected during registration has also been updated.
            </li>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CustomerTerms;
