import React from 'react';
import { useLocation } from 'react-router-dom';

const CustomerPrivacyPolicy = () => {
  const { pathname } = useLocation();

  //  Two different styles based on whether user accesses it from website navbar or as part of signup
  const getFlexStyle = () => {
    return pathname === '/privacypolicy/customerprivacypolicy'
      ? 'flex flex-col self-center mx-auto justify-center mt-12'
      : 'flex flex-col self-center mx-auto';
  };

  const getWidthStyle = () => {
    return pathname === '/privacypolicy/customerprivacypolicy'
      ? 'self-center 3xl:w-1/2 mx-auto justify-center md:w-9/12'
      : 'self-center w-full mx-auto text-left';
  };

  const getTitleStyle = () => {
    return pathname === '/privacypolicy/customerprivacypolicy'
      ? 'text-primary text-2xl font-bold 3xl:pb-24 pt-24 md:pb-12 text-center'
      : ' text-primary text-xl font-bold self-center text-center mb-8';
  };

  const getHeadingStyle = () => {
    return pathname === '/privacypolicy/customerprivacypolicy'
      ? '3xl:text-xl md:text-base font-bold'
      : 'text-base font-bold';
  };

  const getParagraphStyle = () => {
    return pathname === '/privacypolicy/customerprivacypolicy'
      ? 'text-base pt-1 pb-4'
      : 'text-sm pt-1 pb-4';
  };

  const getListStyle = () => {
    return pathname === '/privacypolicy/customerprivacypolicy'
      ? ' text-base pt-1 pb-4 list-disc'
      : ' text-sm pt-1 pb-4 list-disc';
  };

  return (
    <div className={getFlexStyle()}>
      <div className={getWidthStyle()}>
        <p>
          <h1 className={getTitleStyle()}>Customer Privacy Policy</h1>
          <p className={getParagraphStyle()}>Effective Date: September 2020</p>
          <h2 className={getHeadingStyle()}>
            WHAT DOES THIS PRIVACY POLICY COVER?
          </h2>
          <p className={getParagraphStyle()}>
            This privacy policy provides information about the processing of
            your personal information when you sign up and use the Hola Club
            Rewards Programme (the “Rewards Programme”). If you become a member,
            we will create a personal profile. This privacy policy explains what
            personal information we will process, for which purposes, how long
            we will hold your personal information, how you can access your
            personal information and where to go for further information.
          </p>
          <p className={getParagraphStyle()}>
            The provision of your personal information is necessary for your
            participation in the Rewards Programme. A failure to provide your
            personal information will result in you not being able to
            participate in the Rewards Programme.
          </p>
          <h2 className={getHeadingStyle()}>CHILDREN</h2>
          <p className={getParagraphStyle()}>
            We do not intentionally collect data of those under 18 years old. If
            you are under that age, do not send us your personal information
            (for example your name, address and telephone number).
          </p>
          <h2 className={getHeadingStyle()}>
            Who is responsible for your personal information?
          </h2>
          <p className={getParagraphStyle()}>
            Touchsides S.A. (Pty) Ltd (“we” or “us”) is responsible for your
            personal information.
          </p>
          <h2 className={getHeadingStyle()}>
            WHAT PERSONAL INFORMATION DO WE PROCESS?
          </h2>
          <p className={getParagraphStyle()}>
            If you are a member of the Rewards Programme we will collect and use
            the following information about your participation:
          </p>
          <p className={getParagraphStyle()}>
            <strong>Information about you</strong>
          </p>
          <ul className={getListStyle()}>
            <li>Full name and surname</li>
            <li>Cell phone number</li>
            <li>Gender</li>
            <li>Date of birth</li>
            <li>
              Rewards Programme customer loyalty card (“Hola Club card”) ID
              number
            </li>
            <li>Location of the terminal where the card has been tapped </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>
              Purchase and information related to your participation in the
              program
            </strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Information on your past purchases with participating outlets when
              you use your Hola Club card.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>NFC data</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              A near field communications chip has been inserted in your Hola
              Club card. This chip facilitates the communication between your
              Hola Club card and the card reader.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Other</strong>
          </p>
          <ul className={getListStyle()}>
            <li className="my-1"> Details of how you use our services</li>
          </ul>
          <h2 className={getHeadingStyle()}>
            WHY DO WE PROCESS YOUR PERSONAL INFORMATION?
          </h2>
          <p className={getParagraphStyle()}>
            We process your personal information only if one of the following
            legal grounds exists:
          </p>
          <p className={getParagraphStyle()}>
            <strong>Contract</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Where that is necessary to conclude or execute a contract that we
              have with you. For example, to be able to register you as a member
              of the Rewards Programme and to collect and/or redeem rewards.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Legitimate interest</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Where we have a legitimate business interest to do so. We will do
              so only within the boundaries of the data protection laws that
              apply to the processing of your personal information. For example
              to help developing new products or services or to improve current
              products or services of ourselves or the participants in the
              Rewards Programme to maintain or promote our relationship with
              you, to understand consumer and shopping behaviour and trends or
              to personalise the marketing and promotional activities.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Legal obligation</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Where we believe it is necessary to process your personal
              information to comply with a legal obligation to which we are
              subject. For example, to prove that you are legally permitted to
              consume, purchase and buy alcoholic beverages (legal drinking
              age).
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Consent</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Or with your consent. We will always inform you and ask for your
              consent if we need to do so based on the data protection laws that
              apply to the processing of your personal information.
            </li>
          </ul>
          <h2 className={getHeadingStyle()}>
            FOR WHAT PURPOSES DO WE PROCESS YOUR PERSONAL INFORMATION?
          </h2>
          <p className={getParagraphStyle()}>
            We process your personal information for one or more of the
            following purposes:
          </p>
          <p className={getParagraphStyle()}>
            <strong>To operate the Rewards Programme</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              For example to create your user profile and provide you with a
              Hola Club card, to allow you to collect and/or redeem loyalty
              points, or to provide other Rewards Programme related services
              (such as informing you about a new functionality)
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>To compile profiles</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              We create a personal profile of you based on your registration
              information and your previous purchases when using your Hola Club
              card. We can also use other information available to us (for
              example information you provided by a survey). We use this profile
              to understand and predict your purchasing behaviour. We also
              create general group profiles (high level descriptions of
              different types of consumers) based on aggregated information.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>
              To send you personalised and general marketing communication
            </strong>
          </p>
          <ul className={getListStyle()}>
            <li className="my-1">
              One of the benefits of the Rewards Programme is that we can show
              or send you adverts, offers or other marketing communication for
              products that we believe you might be interested in. We can do
              this in collaboration with participating partners. We also can
              send you information about new Rewards Programme services and Hola
              Club card functionalities and other programmes and services
              offered by Touchsides.
            </li>
            <li className="my-1">
              We decide what communication, adverts or offers we show or send to
              you using an automated decision making process which takes into
              account information that you have provided to us as part of your
              participation in the Rewards Programme including, the purchases
              you have made when using your Hola Club card.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>To collect NFC data</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Your Hola Club card contains a near field communication chip to
              establish communication with the device of the participating
              retail owner and recognises you as a member of the Rewards
              Programme. The communication is limited to communication within
              about 1.5 inches and only with other devices that also have been
              equipped with such a chip. Based on the information we know at
              which outlet you are when you use your card.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Monitor and analyse trends</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Based on your information (for example how often you use your Hola
              Club card, your purchase history etc) we can monitor and analyse
              trends and assess the use and effectiveness of the Rewards
              Programme. For example, in relation to purchase behaviour and
              stock availability. We can also use the information to develop new
              products and services based on a better understanding of
              consumers’ preferences and needs.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Participate in research activities</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              We can request you to participate research activities such as:
              surveys, pilots, panels, focus groups, and other research
              activities. Depending on the research activity, we will collect
              different sets of personal information. We will provide research
              activities either with your consent or because we have a
              legitimate interest, depending on the type and nature of the
              research activity.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>
              Allow you to participate in sweepstakes, contest or other
              promotions
            </strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Your registration information in combination with your Hola Club
              card information will be processed to administer, contests or
              other promotions in which you choose to participate. Some of these
              promotions have additional rules containing information about how
              we will use and disclose your personal information. We use this
              information to manage our contractual relationship with you.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Support services</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              Provide support to you in respect of the Rewards Programme and to
              process and respond to any questions or complaints you may have,
              or to update your personal information.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            <strong>Fraud detection and cybersecurity</strong>
          </p>
          <ul className={getListStyle()}>
            <li>
              To prevent and detect security threats, fraud or other malicious
              activity.
            </li>
          </ul>
          <h2 className={getHeadingStyle()}>DIRECT MARKETING</h2>
          <p className={getParagraphStyle()}>
            We currently send you general and individualized direct marketing
            messages in accordance with the Consumer Protection Act, with an
            opportunity to opt out at any time. By becoming a member of the
            Rewards Programme, you consent to our using information about your
            location to show you adverts and offers for products we believe you
            might be interested in. In addition, by becoming a member of the
            Rewards Programme, you consent to us using your information for
            sending direct marketing messages to you by mail, e-mail, text
            messages and other methods of (electronic) communications from time
            to time.
          </p>
          <p className={getParagraphStyle()}>
            Therefore, until the Protection of Personal Information Act, 4 of
            2013 (‘POPI’) comes into effect, you consent to receiving such
            marketing communication and promotional content by signing up for
            the Rewards Programme. After POPI has come into effect, we will
            comply with the consent requirements in accordance with any specific
            consent requirements that may apply under POPI.
          </p>
          <p className={getParagraphStyle()}>
            You have the right to withdraw that consent at any time. For
            example: you can always opt out from receiving marketing
            communications and promotional content. We will stop sending you
            marketing information immediately. As we are in the business of
            marketing and promotions, if you withdraw consent or opt out of
            receiving marketing or promotional messages, your membership in the
            Rewards Programme will be terminated.
          </p>
          <h2 className={getHeadingStyle()}>
            HOW YOU CAN OPT OUT OF RECEIVING DIRECT MARKETING MESSAGES
          </h2>

          <p className={getParagraphStyle()}>
            <strong>Option 1</strong>
          </p>
          <p className={getParagraphStyle()}>
            Once you receive any direct SMS, reply to the message with the
            following phrase: STOP
          </p>
          <p className={getParagraphStyle()}>
            <strong>Option 2</strong>
          </p>
          <p className={getParagraphStyle()}>
            You can send “STOP” to a short code (43739)
          </p>
          <h2 className={getHeadingStyle()}>
            HOW LONG DO WE KEEP YOUR INFORMATION?
          </h2>
          <p className={getParagraphStyle()}>
            We will keep your information as long as is necessary to fulfil the
            purposes that we describe in this policy.
          </p>
          <p className={getParagraphStyle()}>As a general rule we will keep:</p>
          <ul className={getListStyle()}>
            <li className="my-1">
              The information you provide when you register with the Rewards
              Programme, together with any updates, until a period of 6 months
              after the termination of your membership with the program
            </li>
            <li className="my-1">
              Other information. The information we collect about you and your
              use of the Rewards Programme (including for example information
              about you past purchases when using the Hola Club card) for a
              period of 36 months after the moment we have collected the
              information. After this period, we will delete or de-identify this
              information and only use the resulting data on an aggregated level
              for statistical purposes. For example, for trend analysis or the
              creation of group profiles (consumer segmentation).
            </li>
          </ul>
          <h2 className={getHeadingStyle()}>
            WHO WILL WE SHARE YOUR INFORMATION WITH?
          </h2>
          <p className={getParagraphStyle()}>
            We are a member of the global HEINEKEN group of companies,
            information about the HEINEKEN group of companies can be found here:
            www.heinekencompany.com. We may share your information with our
            parent company if we require support services from the parent
            company such as information technology support/maintenance, legal
            support and to perform certain functions which we are not able to
            perform, without their support. If it would be sufficient to achieve
            the business purposes for which we are sharing data, we will only
            share data on an aggregated or de-identified level. We may also
            share your information with:
          </p>
          <ul className={getListStyle()}>
            <li className="my-1">
              other organisations and service providers that we work with or
              partner with from time to time including, for example: third party
              websites, brand owners (companies participating in the Rewards
              Programme and their group companies), analytics providers and
              advertising and media companies to enable them to show you adverts
              or offers which may interest you, including online, brands and
              data companies: for purposes of marketing, promotional activities
              and data collection on consumer and other demographics;
            </li>
            <li className="my-1">Our professional advisors.</li>
            <li className="my-1">
              Any law enforcement agency, court, regulator, government authority
              or other third party where we believe this is necessary to comply
              with a legal or regulatory obligation, or otherwise to protect our
              rights or the rights of any third party.
            </li>
            <li className="my-1">
              Any party that purchases, or to which we transfer, all or
              substantially all of our assets and business relating to the
              Rewards Programme. Should such a sale or transfer occur, we will
              use reasonable efforts to try to ensure that the entity to which
              we transfer your information uses it in a manner that is
              consistent with this policy.
            </li>
          </ul>
          <h2 className={getHeadingStyle()}>
            TRANSFER OF YOUR PERSONAL INFORMATION TO OTHER COUNTRIES
          </h2>
          <p className={getParagraphStyle()}>
            Your personal information may be transferred to another country. For
            example, if your information is being hosted in a data centre
            outside South Africa, if we are able to remotely access your data
            from abroad or one of our IT suppliers provides off-premise
            maintenance and support services from outside South Africa.
            Countries to which we transfer personal information may have
            different privacy standards than the ones in South Africa. If we
            transfer your personal information to a country which does not offer
            an adequate level of protection we will ensure that we have put in
            place adequate safeguards to protect your personal information or
            otherwise ensure that we can transfer your information in a way that
            complies with data protection law.
          </p>
          <h2 className={getHeadingStyle()}>
            SECURITY OF YOUR PERSONAL INFORMATION
          </h2>
          <p className={getParagraphStyle()}>
            We will take appropriate, technical, physical and organisational
            measures to protect your personal information collected through the
            Rewards Programme from misuse or accidental, unlawful or
            unauthorized destruction, loss, alteration, disclosure, acquisition
            or access, that are consistent with applicable privacy practices and
            data security laws and regulations.
          </p>
          <p className={getParagraphStyle()}>
            Where we contract with any service providers, we require the service
            providers to use appropriate measures to protect the confidentiality
            and security of your personal information.
          </p>
          <h2 className={getHeadingStyle()}>WHAT RIGHTS DO YOU HAVE?</h2>
          <p className={getParagraphStyle()}>
            You have a number of rights in relation to your personal
            information. For example: you can decide not to receive marketing
            information from us, where we rely on your consent for the
            processing of your data you may withdraw your consent at any time
            and you can object to some of the ways we use your personal
            information. Note that some of the choices you make may impact or
            limit our ability to maintain and manage your membership with the
            Rewards Programme. Your membership may even have to be terminated.
            We will notify you if such action is necessary. You can request us
            (using the details below) at any time:
          </p>
          <ul className={getListStyle()}>
            <li className="my-1">
              To access your personal information (i.e. obtain an overview of
              your personal information we process).
            </li>
            <li className="my-1">
              To have your personal information corrected, updated rectified or
              erased or to restrict the processing of your personal information.
            </li>
            <li className="my-1">
              To receive a copy of your personal information in a commonly
              machine-readable format or to have this information transmitted
              directly to another organisation (if technically possible).
            </li>
            <li className="my-1">
              To object to the processing of your personal information at any
              time, on reasonable grounds relating to your particular situation.
            </li>
            <li className="my-1">
              To object to the processing of your personal information, at any
              time, if the processing is for purposes of direct marketing by
              means of unsolicited electronic communications and you have not
              given your consent for that processing.
            </li>
            <li className="my-1">
              To lodge a complaint with the Information Regulator of South
              Africa via email at inforeg@justice.gov.za. Further details about
              the Information Regulator can be found at
              https://www.justice.gov.za/inforeg/.
            </li>
          </ul>
          <p className={getParagraphStyle()}>
            To make sure that we do not provide information about you to someone
            else, we may ask for your identification before we can process your
            request.
          </p>
          <h2 className={getHeadingStyle()}>HOW TO CONTACT US</h2>
          <p className={getParagraphStyle()}>
            If you have any questions about how we treat your personal
            information or if you want to exercise your rights in relation to
            the personal information we process, please contact us at
            info@holaclub.co.za or by calling +27 (10) 3125105 or (toll free)
            0800 014809.
          </p>
          <h2 className={getHeadingStyle()}>CHANGES TO THIS POLICY</h2>
          <p className={getParagraphStyle()}>
            We may amend this privacy policy within the limitations provided by
            the applicable laws such as POPI and consumer protection related
            legislation. We advise you to regularly visit our website (as
            indicated below) for possible changes to the privacy policy. Any
            material changes to the privacy policy will be included on the
            holaclub.co.za website.
          </p>
          <h2 className={getHeadingStyle()}>
            TOUCHSIDES S.A. PROPRIETARY LIMITED INFORMATION
          </h2>
          <p className="pt-2"></p>
          <p className={getParagraphStyle()}>
            <p>
              <strong>Full name:</strong> Touchsides S.A. (Proprietary) Limited
            </p>
            <p>
              <strong>Physical Address:</strong> President Place 3rd Floor Cnr.
              Jan Smuts Avenue & Bolton Road, Rosebank, Johannesburg, South
              Africa 2196
            </p>
            <p>
              <strong>Telephone No:</strong> +27 (10) 312 5105
            </p>
            <p>
              <strong>Website Address:</strong> www.touchsides.com
            </p>
            <p>
              <strong>Email Address:</strong> info@touchsides.com
            </p>
            <p>
              <strong>Registration No.:</strong> 2007/007727/07
            </p>
            <p>
              <strong>Country of incorporation:</strong> South Africa
            </p>
          </p>
          <p className="pb-2"></p>
          <h2 className={getHeadingStyle()}>CHANGE LOG AS AT JULY 2021</h2>
          <ul className={getListStyle()}>
            <li className="my-1">
              <b>Section 4: What personal information do we process?</b>
              <li className="my-1">
                We no longer collect your email address, Identity number or
                Passport number.
              </li>
            </li>
            <li className="my-1">
              <b>Section 7: Direct Marketing</b>
              <li className="my-1">
                The clause has been update with reference to the Consumer
                Protection Act, consent to receive direct marketing, your right
                to withdraw consent and how to opt out.
              </li>
            </li>
            <li className="my-1">
              <b>Section 9: Who will we share your information with?</b>
              <li className="my-1">
                This has been updated to include the HEINEKEN Group of
                companies.
              </li>
            </li>
            <li className="my-1 pb-4">
              <b>Section 12: What rights do you have?</b>
              <li className="my-1">
                You may object the to the processing of your personal
                information. Please refer to section 12 for details.
              </li>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default CustomerPrivacyPolicy;
